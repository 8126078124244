/* Imports */
@font-face {
    font-family: 'Gilmer Bold';
    src: url("../fonts/Gilmer/Gilmer\ Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: 'Gilmer Heavy';
    src: url("../fonts/Gilmer/Gilmer\ Heavy.otf") format("opentype");
    font-weight: 900;
}

@font-face {
    font-family: 'Gilmer Light';
    src: url("../fonts/Gilmer/Gilmer\ Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: 'Gilmer Medium';
    src: url("../fonts/Gilmer/Gilmer\ Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: 'Gilmer';
    src: url("../fonts/Gilmer/Gilmer\ Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: 'Gilroy';
    src: url("../fonts/Gilroy/Gilroy-Light.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url("../fonts/Gilroy/Gilroy-ExtraBold.otf") format("opentype");
    font-weight: 700;
}

body {
    font-family: 'Gilmer', sans-serif;
    background: #000;
}

p {
    margin-bottom: 0rem;
}



.center {
    margin: auto;
    width: 50%;
    border: 1px solid #d8ddd8;
    padding: 10px;
}

:root {
    --glimer-bold-font: 'Gilmer Bold', sans-serif;
}

.container-fluid {
    font-family: 'Gilmer', sans-serif;
}

#AsideNav p {
    margin-bottom: 0rem;
    margin-left: 8px;
    font-family: 'Gilmer Medium', sans-serif;
}

.flex-normal {
    display: flex;
    align-items: flex-start;
}

.fl-center-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.flex-column {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

@media screen and (max-width:900px) {
    .fl-col-responsive {
        flex-direction: column;
        flex-wrap: wrap;
    }
}


.pagination .page-item.active .page-link {
    background-color: #FF5A00 !important;
    border-color: #FF5A00 !important;
}