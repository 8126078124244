.custom-loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #ffffff80;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.relative {
    position: relative !important;
    width: 100%;
    height: calc(100vh - 150px);
    background-color: transparent;
}

.custom-loader span {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #000;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    animation: spin 0.7s linear infinite;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.custom-loader_456 {
    position: relative;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;

}

.custom-loader_456 div {
    width: 26px !important;
    height: 26px;
    border-radius: 50%;
    border: 3px solid #000;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    animation: spin 0.7s linear infinite;

}