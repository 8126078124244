@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,200;0,400;0,500;0,700;1,200;1,400;1,500;1,700&family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@200;400;600;700&display=swap");
@import "./vars.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    cursor: pointer;
}

#root {
    width: 100%;
    background-color: #fafafa;
}

::-webkit-scrollbar {
    width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// 
@mixin mxw-1600px {
    max-width: 1600px;
}

.container-fluid {
    @include mxw-1600px;
}

input:-webkit-autofill {
    -webkit-background-clip: text;
}

:root {
    font-size: 16px;
    line-height: 25px;

    @media (max-width: 1200px) {
        font-size: 12px;
        line-height: 18px;
    }

    @media (max-width: 800px) {
        font-size: 8px;
        line-height: 13px;
    }
}

i,
svg,
option:hover {
    cursor: pointer;
}

select:hover {
    cursor: pointer;
}

a {
    text-decoration: none;
}

body {
    font-family: "Montserrat", sans-serif;
    font-family: "Montserrat Alternates", sans-serif;
    font-family: "Poppins", sans-serif;
}

h1 {
    font-size: 2rem;
    font-weight: 600;
}

@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-green {
    color: $color_green !important;
}

.hover {
    cursor: pointer;
}

.text-blue {
    color: blue;
}

.text-blue {
    color: $color_blue;
}

.text-purple {
    color: $color_purple;
}

.text-orange {
    color: $light_orange;
}

.text-dark-orange {
    color: $color_orange !important;
}

.text-light-gray {
    color: $icon_dark;
    font-weight: 600;
    font-size: 0.9rem;
}

.text-pending {
    color: $light_orange;
    background: #fae7d3;
    font-size: 0.7rem;
    font-weight: 400;
    max-width: fit-content;
    padding: 0.1rem 0.5rem;
    border-radius: 10px;
}

.text-success {
    color: $color_green;
    background: #e8fce9;
    font-size: 0.7rem;
    font-weight: 400;
    max-width: fit-content;
    padding: 0.1rem 0.5rem;
    border-radius: 10px;
}

.Distributor .main_content.money_transfer .dashboard .age_group .btn_age.active {
    background: linear-gradient(#343352 0%, #5F5E7D 100%)
}

.Distributor .main_content.money_transfer .dashboard .btn.btn_small.active {
    background: linear-gradient(135deg, #FF7900, #FF8E19);
}

input::placeholder {
    color: #c1c1c173 !important;
}

.text-failed {
    color: $color_red;
    background-color: #fde3e7;
    font-size: 0.7rem;
    font-weight: 400;
    max-width: fit-content;
    padding: 0.1rem 0.5rem;
    border-radius: 10px;
}

.Distributor {
    color: $text_Brand;
    background: #fafafa;
    min-height: 100vh;
    height: auto;

    .nav {
        background: $color_white;
    }

    .head {
        line-height: 60px;

        .list-group {
            .list-group-item {
                color: $icon_dark;

                .btn,
                .btn:active,
                .btn:focus {
                    outline: none;
                    box-shadow: none;
                    border: 1px solid rgba(216, 216, 216, 0.5);
                    border-radius: 6px;
                    background: $color_white;
                    color: $icon_dark;
                    padding: 8px 15px;
                    position: relative;
                    transition-duration: 0.4s;
                    text-decoration: none;
                    overflow: hidden;
                    cursor: pointer;
                }

                .btn:after {
                    content: "";
                    background: $color_white;
                    display: block;
                    position: absolute;
                    padding-top: 300%;
                    padding-left: 350%;
                    margin-left: -20px !important;
                    margin-top: -120%;
                    opacity: 0;
                    transition: all 0.4s;
                }

                .btn:active:after {
                    padding: 0;
                    margin: 0;
                    opacity: 1;
                    transition: 0s;
                }
            }
        }
    }

    .mainbody {
        .aside {
            border-radius: 10px;
            background: $color_white;

            .aside_item {
                font-size: 12px;
                line-height: 19px;
            }

            .aside_head {
                h5 {
                    font-size: 9px;
                    line-height: 11px;
                    color: $natural_inactive;
                    position: relative;
                }

                h5:after {
                    content: "";
                    display: block;
                    height: 1px;
                    width: 140px;
                    margin-left: 6px;
                    background-color: $natural_inactive;
                    position: absolute;
                    right: 0;
                    top: 50%;
                }
            }
        }
    }

    .main_content {
        table {
            font-size: 14px;
            line-height: 17px;

            thead {
                background-color: $light_white;
            }

            tr {
                .client_name {
                    font-size: 14px;
                    line-height: 17px;
                }

                .store_name {
                    font-size: 12px;
                    line-height: 15px;
                    margin: 0.5rem 0;
                }

                th,
                td {
                    vertical-align: middle;
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid $light_border;
                }
            }
        }

        .dashboard {
            >h5 {
                font-weight: 700;
            }

            >p {
                color: $natural_inactive;
            }

            background: $color_white;
            border-radius: 10px;

            .box {
                border: 1px dashed rgba(216, 216, 216, 0.7);
                width: 20%;
                border-radius: 10px;

                p {
                    font-size: 0.7rem;
                    font-weight: 400;
                }

                h5 {
                    font-weight: 600;
                }
            }
        }

        .merchant {
            background-color: $color_white;
            border-radius: 10px;
            // padding: 0 1rem;

            .section-header {
                padding: 1rem 0.5rem;

                h5 {
                    font-family: 'Gilmer Bold', sans-serif;
                }

                p {
                    font-family: 'Gilmer Medium', sans-serif;
                }
            }
        }

        &.money_transfer {
            .dashboard {
                min-height: 600px;
                height: auto;

                .option {
                    display: inline-block;
                    margin-right: 1rem;

                    input[type="radio"]:after {
                        width: 15px;
                        height: 15px;
                        border-radius: 15px;
                        top: -2px;
                        left: -1px;
                        position: relative;
                        background-color: $color_white;
                        content: "";
                        display: inline-block;
                        visibility: visible;
                        border: 2px solid $money_transfer_color;
                    }

                    input[type="radio"]:checked:after {
                        width: 15px;
                        height: 15px;
                        border-radius: 15px;
                        top: -2px;
                        left: -1px;
                        position: relative;
                        background-color: $money_transfer_color;
                        content: "";
                        display: inline-block;
                        visibility: visible;
                        border: 1px solid $money_transfer_color;
                    }
                }

                .age_group {
                    .btn_age {
                        background: rgba(95, 104, 116, 0.3);
                        border: none;
                        padding: 0.2rem 1rem;
                        margin-right: 0.5rem;
                        border-radius: 0.5rem;

                        &.active {
                            background: $text_Brand;
                            color: $color_white;
                        }
                    }
                }

                h5 {
                    color: $money_transfer_color;
                    font-weight: 600;

                    &.invalid_user {
                        color: $color_red;
                    }
                }

                input[type="mobile"],
                input[type="mobile"]:focus,
                input[type="mobile"]:active,
                select {
                    border: none;
                    outline: none;
                    box-shadow: none;
                    border-bottom: 2px solid $color_orange;
                    border-radius: 0;
                    font-size: 1.25rem;
                    font-weight: 500;
                    color: $text_Brand;
                    line-height: 1.5rem;
                    padding: 0;

                    &.gray_border {
                        border-bottom: 1px solid $color_radio;
                    }

                    &.otp {
                        border-bottom: 1px solid $color_radio;
                    }
                }

                ::placeholder {
                    font-weight: 400;
                }

                .btn {
                    background: linear-gradient(135deg, #ff7900 0%, #ff8e19 100%);
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 10px;
                    color: $color_white;
                    position: relative;
                    transition-duration: 0.4s;
                    text-decoration: none;
                    overflow: hidden;
                    cursor: pointer;
                    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);

                    p {
                        display: inline-block;
                        margin: 0;
                        color: $color_white;
                    }

                    &.btn_small {
                        width: 200px;
                        float: right;
                        background: $color_radio;
                        padding: 0.5rem;
                    }
                }

                .btn:after {
                    content: "";
                    background: $color_white;
                    display: block;
                    position: absolute;
                    padding-top: 300%;
                    padding-left: 350%;
                    margin-left: -20px !important;
                    margin-top: -120%;
                    opacity: 0;
                    transition: all 0.8s;
                }

                .btn:active:after {
                    padding: 0;
                    margin: 0;
                    opacity: 1;
                    transition: 0s;
                }

                .login {
                    position: relative;

                    input[type="mobile"]::placeholder {
                        color: $icon_dark;
                        font-weight: 300;
                    }

                    &.login_head {
                        display: flex;

                        img {
                            margin-right: 1rem;
                            top: 0;
                        }

                        .head_content {
                            p {
                                color: $text_Brand;
                                font-weight: 600;
                            }

                            span {
                                font-weight: 300;
                                font-size: 0.8rem;
                            }
                        }
                    }

                    .resend_btn {
                        position: absolute;
                        border: none;
                        right: 0;
                        bottom: 10px;
                        padding: 0.3rem 0.5rem;
                        color: #fff;
                        background: linear-gradient(135deg, #ff7700c9 0%, #ff9019b2 100%);
                    }

                    p {
                        color: $money_transfer_color;
                    }
                }

                .profile_name {
                    background-color: rgba(214, 220, 228, 0.4);
                    @include center;
                    border-radius: 50%;
                    width: 3.5rem;
                    height: 3.5rem;
                    padding: 1rem;
                    margin-right: 1rem;

                    h1 {
                        font-weight: 200;
                        font-size: 1.25rem;
                    }
                }

                .header {
                    h5 {
                        font-size: 1rem;
                        line-height: 1.25rem;
                        color: $text_Brand;
                    }

                    p {
                        font-size: 0.9rem;
                        line-height: 1.25rem;
                        color: $color_radio;
                    }
                }

                .list-group {
                    position: relative;

                    .list-group-item {
                        border: none;
                        text-align: center;

                        &.active {
                            background: none;
                            color: $natural_inactive;
                            border-bottom: 2px solid $natural_inactive;
                        }
                    }
                }

                .progress {
                    height: 65%;
                    text-align: center;
                    color: $text_Brand;
                    background: $light_border;
                    border-radius: 0.5rem;

                    .progress-bar {
                        background: $progress_color;
                    }
                }

                .progress_text {
                    top: 0;
                    width: 100%;

                    p {
                        font-size: 0.6rem;
                        line-height: 2.5rem;
                        text-align: center;
                        padding-right: 0.5rem;

                        span {
                            font-size: 0.8rem;
                            font-weight: 600;
                        }
                    }
                }

                .pages {
                    button {
                        background: none;
                        font-size: 0.8rem;
                        color: $natural_inactive;
                    }

                    span {
                        color: $natural_inactive;
                        display: inline-block;
                        width: 1.5rem;
                        height: 1.5rem;
                        font-size: 0.8rem;
                        line-height: 1.5rem;
                        font-weight: 300;
                        text-align: center;

                        &.active {
                            border: 1px solid $money_transfer_color;
                            border-radius: 50%;
                            color: $money_transfer_color;
                        }
                    }
                }

                .contact_details {
                    button {
                        background: none;
                        border: 1px solid $text_Brand;
                        padding: 0.3rem 0.5rem;
                    }

                    .dropdown-menu {
                        .dropdown-item {
                            color: $natural_inactive;
                            font-weight: 300;

                            &:hover {
                                font-weight: 400;
                                color: $text_Brand;
                            }
                        }
                    }
                }

                .tab-content {
                    .tab-pane {
                        @include center;

                        // p {
                        //     font-size: 1rem;
                        //     line-height: 1.25rem;
                        //     color: $text_Brand;
                        //     font-weight: 600;
                        // }

                        a {
                            color: $money_transfer_color;

                            p {
                                color: $money_transfer_color;
                                font-weight: 400;
                            }
                        }

                        h5 {
                            color: $text_Brand;
                        }

                        table {
                            thead {
                                background: none;
                                border-bottom: 5px solid $light_border;

                                th {
                                    font-weight: 600;
                                }
                            }

                            tbody {
                                td {
                                    font-weight: 400;
                                    font-size: 0.7rem;
                                }
                            }
                        }
                    }
                }

                .list-group::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: $light_border;
                    bottom: 0;
                }
            }

            .modal {
                margin: 0;

                .modal-dialog {
                    float: right;
                    min-height: 100vh;
                    margin: 0;

                    .modal-content {
                        min-height: 100vh;
                        min-width: 500px;
                        border-radius: 1rem;

                        .modal-header {
                            border: none;
                            padding: 2rem;
                        }

                        .fetch {
                            right: 0;
                            background: none;
                            padding: 0 0.5rem;
                            font-size: 0.8rem;
                        }

                        h5 {
                            color: $text_Brand;
                        }

                        label {
                            color: $natural_inactive;
                        }

                        p {
                            font-weight: 300;
                        }
                    }
                }
            }

            .send_money_modal {
                .modal-dialog {
                    padding-top: 3rem;
                    margin: auto;
                    float: none;
                    min-height: fit-content;

                    .modal-content {
                        min-height: fit-content;
                        min-width: fit-content;

                        .modal-header {
                            border: none;
                            padding: 2rem;
                        }
                    }
                }
            }

            .send_money {
                .fetch {
                    right: 0;
                    background: none;
                    padding: 0.3rem 0.5rem;
                    font-size: 0.8rem;
                }

                h5 {
                    color: $text_Brand;
                }

                label {
                    color: $natural_inactive;
                }

                p {
                    font-weight: 300;
                }
            }
        }
    }
}

.error-message {
    font-size: 12px;
    color: red;
}

.back-btn {

    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    font-family: Montserrat;
    margin-bottom: 30px;
    opacity: .7;
    cursor: pointer;

    img {
        transition: .3s;
    }

    &:hover {
        opacity: 1;

        img {
            transform: translate(-4px);
        }
    }

    img {
        margin-right: 7px;
    }
}

// select.form-select {
//     option {
//         font-weight: 700;
//     }
// }

input.form-control {
    border: none;
    box-shadow: none !important;
    border-radius: unset;
    border-bottom: 2px solid #FF5A00;
    outline: 0;
    font-weight: 700;
}

input.form-control:focus {
    border-color: #FF5A00 !important;
}

.main-body {
    min-height: 100vh;

    .left_section {
        background-color: #fff7f7;
        position: relative;

        .img {
            position: absolute;
            bottom: 100px;
            z-index: 10;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .card {
            background: transparent;
            border: none;
            position: absolute;
            top: 150px;
            left: 50%;
            width: auto;
            transform: translate(-50%);

            .card-item {
                span {
                    display: inline-block;
                    min-width: 25px;
                    border: 1px solid $icon_dark;
                    border-radius: 50%;
                    text-align: center;
                    margin: 0 1rem;
                    line-height: 21px;

                    @media (max-width: 576px) {
                        min-width: 14px;
                        width: 20px;
                        line-height: 17px;
                    }
                }

                h5 {
                    display: inline-block;
                    color: $natural_inactive;
                    font-size: 1.2rem;

                    &.active {
                        font-weight: 700;
                        color: $text_Brand;
                    }
                }

                .address_option {
                    padding: 0.5rem 0.6rem;
                    width: fit-content;
                    margin-left: 22%;

                    h5 {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .right_section {
        background-color: $color_white;

        h5,
        label {
            color: $text_Brand;
            font-weight: 600;
        }

        p {
            color: $natural_inactive;
        }

        input[type="mobile"],
        input[type="mobile"]:focus,
        input[type="mobile"]:active,
        select {
            border: none;
            outline: none;
            box-shadow: none;
            border-bottom: 1px solid $color_orange;
            border-radius: 0;
            font-size: 1.25rem;
            font-weight: 300;
            line-height: 1.5rem;
            padding: 0;

            &.gray_border {
                border-bottom: 1px solid $color_radio;
            }
        }

        ::placeholder {
            font-weight: 200;
            font-size: 1.2rem;
        }

        select {
            color: $color_radio;
        }

        .content_box {
            min-height: 90%;

            .btn {
                background: linear-gradient(135deg, #ff7900 0%, #ff8e19 100%);
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 10px;
                color: $color_white;
                position: relative;
                transition-duration: 0.4s;
                text-decoration: none;
                overflow: hidden;
                cursor: pointer;

                p {
                    display: inline-block;
                    margin: 0;
                    color: $color_white;
                }
            }

            .btn:after {
                content: "";
                background: $color_white;
                display: block;
                position: absolute;
                padding-top: 300%;
                padding-left: 350%;
                margin-left: -20px !important;
                margin-top: -120%;
                opacity: 0;
                transition: all 0.8s;
            }

            .btn:active:after {
                padding: 0;
                margin: 0;
                opacity: 1;
                transition: 0s;
            }

            .otp_area {
                display: flex;

                input,
                input:focus,
                input:active {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }

                input {
                    text-align: center;
                    max-width: 40px;
                    padding: 10px 5px;
                    margin: 5px;
                    font-size: 20px;
                    line-height: 24px;
                    background: rgba(214, 220, 228, 0.3);
                }
            }

            .resend_otp {
                display: flex;
                justify-content: space-between;

                p {
                    display: inline-block;

                    a {
                        text-decoration: none;
                        color: $color_orange;
                    }
                }
            }

            .qr_text {
                a {
                    color: $color_orange;
                }

                p {
                    font-size: 1rem;
                    line-height: 1.25rem;
                    color: $text_Brand;
                }
            }

            .BharatATM_download {
                a {
                    color: $color_orange;
                    text-decoration: none;
                    position: relative;
                    margin: 0 10px;
                }

                a:after {
                    content: "";
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: -1.5px;
                    border-width: 0 0 1px;
                    border-style: solid;
                }
            }

            .option {
                border: 1px solid $color_radio;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem;
                border-radius: 0.5rem;

                label {
                    color: $text_Brand;
                    margin: 0 0.5rem;
                    font-weight: 400;
                }
            }

            .upload_box {
                .upload_aadhar {
                    button {
                        display: block;
                        width: 120px;
                        height: 30px;
                        border: 1px solid $color_radio;
                        background: none;
                        border-radius: 0.5rem;
                        color: $icon_dark;
                        font-weight: 300;
                    }

                    input[type="file"] {
                        display: none;
                    }

                    h5 {
                        font-size: 1.15rem;
                        line-height: 1.5rem;
                        font-weight: 500;
                    }

                    p {
                        font-size: 0.75rem;
                        line-height: 1rem;
                    }

                    .error {
                        color: $color_red;
                    }

                    .success {
                        color: $color_green;
                    }
                }
            }
        }

        .footer {
            span {
                font-weight: 700;
                color: $text_Brand;
            }
        }
    }
}

.popup.d-flex.position-absolute {
    bottom: 0;
    right: 3rem;
    width: 23rem;
    background: $color_white;
    box-shadow: 1px 3px 10px $color_radio;
    padding: 1rem;
    align-items: center;
    border-radius: 0.5rem;

    h5 {
        font-size: 0.9rem;
        margin: 0 1rem;
    }

    .close {
        top: 0.5rem;
        right: 1rem;
    }
}

.center {
    margin: auto;
    width: 50%;
    border: 1px solid #d8ddd8;
    padding: 10px;
}

.nav-box-offer {
    height: 70%;
}

.text-center {
    text-align: center;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.heading-text {
    height: 27px;
    width: 126px;
    color: #343352;
    font-family: Gilroy-Bold;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 27px;
}

.inside-text {
    height: 72px;
    width: 167px;
    color: #6C727A;
    font-family: Gilroy-Medium;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}

.bharat-petroleum {
    height: 14px;
    width: 114px;
    color: #343352;
    font-family: Gilroy-Medium;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}

.cashback-text {
    height: 14px;
    width: 262px;
    color: #343352;
    font-family: "Gilmer Medium";
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 14px;
}

.unlimited-reading-bo {
    height: 69px;
    width: 286px;
    color: #343352;
    font-family: "Gilmer Medium";
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 23px;
}

.fassos {
    height: 14px;
    width: 44px;
    color: #343352;
    font-family: Gilroy-Medium;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}

.ola {
    height: 14px;
    width: 28px;
    color: #343352;
    font-family: Gilroy-Medium;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}

.bp {
    box-sizing: border-box;
    height: 56px;
    width: 56px;
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.sidebar-text:hover {
    color: #ff9100;
}

.error {
    color: #fc8181;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;

}

.table-borderless {
    position: relative;
}

.form-control[readonly] {
    background-color: #fff !important;
}

.form-control.otp {
    letter-spacing: 3px;
}

.f16 {
    font-size: 16px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    //   margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}

@media print {

    @page {
        margin: 2cm;
        font-size: 40px;
        // width:90%!important
    }
}


.react-select__control {
    border: none !important;
    border-bottom: 2px solid #FF5A00 !important;
    border-radius: 0 !important;
}




.css-1s2u09g-control {
    outline: none !important;
}


#SignupContainer {
    position: relative;
    height: max-content;

    .container-fluid {
        height: 100%;

        .aside-container.left_section {
            height: 100%;

            .img {
                margin-right: 40px;
            }
        }

        h1 {
            font-size: 36px;
            letter-spacing: -0.1px;
            font-family: 'Gilmer Bold', sans-serif;
            // font-family: 'Gilroy Bold',sans-serif;
            color: #343352;
            margin-bottom: 6px;

            &+p {
                font-size: 15px;
                color: #6c727afa;
                line-height: 1.7;
            }

        }

        .content {
            width: 360px;
            padding-top: 60px;
            padding-left: 10px;
        }

        .BharatATM_download {
            font-size: 15px;
            margin-top: 20px !important;
            color: #97999c;

            span {
                // t-size: 14px;
                font-family: 'Gilmer Bold', sans-serif;

                a {
                    color: #f38524fa;
                }
            }
        }

        form {
            margin-top: 20px;

            label {
                margin: 0;
                // font-family: 'Gilmer Light ',sans-serif;
            }

            input {
                width: calc(100% - 20px);
                padding: 0px 10px 6px 0;
                font-size: 20px;
                letter-spacing: 1px;
                font-family: 'Gilroy Medium', sans-serif;
            }

            .btn {
                width: calc(100% - 20px);
                letter-spacing: 1.1px;
                margin-top: 28px;
                font-size: 14px;
                font-family: 'Gilmer Medium', sans-serif;
            }
        }
    }
}

.react-select__control {
    box-shadow: none !important;
}

.h-0 {
    height: auto !important;
    min-height: auto !important;
}

.b-radius {
    border-radius: 0 !important;
}

/* global css */
.dataTable-filter_input {
    width: initial !important;
    margin: 0 4px 0 !important;
    padding: .1rem 1.5rem .1rem 0.5rem !important;
}

.table td.fit,
.table th.fit {
    white-space: nowrap;
    width: 1%;
}

.cursor-pointer {
    cursor: pointer !important;
}

.selectBankList {
    font-size: initial !important;
}

.commonPara {
    text-align: justify;
    color: #424242;
}

.f-16 {
    font-size: 16px !important;
}

.v-hidden {
    visibility: hidden !important;
}

.txt-success {
    color: #26A62E !important;
}

.txt-warning {
    color: #FF5A00 !important;
}

.txt-danger {
    color: #F00 !important;
}

.txt-disabled {
    color: #8b8989 !important
}

.txt-justify {
    text-align: justify !important;
}

.date_picker .MuiInputBase-input {
    padding: 8px 14px !important;
}

.btn_background {
    background: linear-gradient(135deg, #FF7900, #FF8E19) !important;
    border-color: #FF8E19 !important;
}

.l-caps {
    text-transform: capitalize !important;
}

.grecaptcha-badge {
    display: none !important;
}

.otp_inputs:focus,
.otp_inputs:active,
.otp_inputs:hover,
.otp_inputs:target {
    border: 2px solid #ff8200 !important;
}

.justify-content {
    justify-content: space-around !important;
}

span.req_input {
    font-size: 1rem !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.Toastify__progress-bar--batminfo {
    background: #FF5A00 !important;
}

.text_theme_color {
    color: #343352 !important;
}

.batm_theme_border_color {
    border-color: #FF5A00 !important;
}

.batm_theme_border_boottom_color {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-color: #FF5A00 !important;
}

.text_theme_color.active {
    font-weight: 600 !important;
}

.batm-light-success.bg-success {
    color: #0b7038 !important;
    background-color: rgba(40, 199, 111, .12) !important;
}

.date_picker label.MuiFormLabel-root {
    top: -7px !important;
}

.export_btn_light {
    color: #000 !important;
    background-color: #f8f9fa !important;
    background: #f8f9fa !important;
    border-color: #f8f9fa !important;

    padding: 0.25rem 0.5rem !important;
    font-size: .875rem !important;
    border-radius: 0.2rem !important;
}

.batm_search_button {
    width: initial !important;
    background-color: #343352 !important;
    background: #343352 !important;
    color: #ffffff !important;
    border-radius: 0.25rem !important;
}

.batn_reset_filter_button {
    width: initial !important;
    color: #000 !important;
    background-color: #f8f9fa !important;
    background: #f8f9fa !important;
    border-color: #f8f9fa !important;
    border-radius: 0.25rem !important;
}

/* --------------- Common CSS ------------- */