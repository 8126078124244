.alert_container {
    z-index: 99999 !important;
    position: relative !important;
}

.maintenance_img_div {
    max-width: 400px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.main_body {
    background-color: #f8f8f8 !important;
}