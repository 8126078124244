.aside-container {
    min-height: 100vh;
    @media (max-width: 576px) {
        min-height: (95vh);
    }
    &.left_section{
        padding-top: 41.22px;
        svg{
            margin-left: -10px;
        }
    }
}
.f16{
    font-size: 16px !important;
    font-family: 'Glimer Bold',sans-serif;
}