// colors
$text_Brand:#343352;
$natural_inactive:#6C727A;
$color_orange:#FF5A00;
$color_white:#fff;
$icon_dark:#335B65;
$light_white:#F5F5F5;
$color_blue:#599EFA;
$color_green:#26A62E;
$color_purple:#CD61DF;
$light_orange:#EF9C47;
$light_border:#D6DCE4;
$color_red:#D9203C;
$color_radio: #C1C1C1;
$money_transfer_color:#Ff7900;
$progress_color:#D3E4F8;
@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}