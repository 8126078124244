#blog_whats_new .title {
    text-align: center;
    font-size: 36px;
    letter-spacing: -0.1px;
    font-family: 'Gilmer Bold', sans-serif;
    color: #343352;
    margin-bottom: 6px;
}

#blog_whats_new .content {
    min-height: 400px;
    width: 100% !important;
    text-align: justify;
}


/* #blog_whats_new .text {
    font-size: 12px;
    text-align: justify !important;
} */


#blog_old_transaction_history .content {
    text-align: center;
}

#blog_old_transaction_history .content p img {
    margin: auto !important;
}