.body {
    /* min-height: 50vh; */
    /* display: flex; */
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    flex-direction: column;
}

.body span {
    font-size: 100px;
}

.para {
    text-align: justify;
    padding: 10px;
    color: #5a5a5a;
    letter-spacing: 1px;
}