.otpInput_custom {
    width: 36px !important;
    height: 46px;
    margin-right: 10px;
    font-size: 20px;
    font-family: 'Gilroy Medium', sans-serif;
    background-color: rgba(214, 220, 228, 0.3);
    border-radius: 6.5px;
    border: 1px solid transparent;
    margin-top: 3px;
    padding: 0 !important;
    text-align: center;
    outline: none !important;
}