.body{
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    flex-direction: column;
}
.body span{
    font-size: 100px;
}